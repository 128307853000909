@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?6udzqb");
  src: url("fonts/icomoon.eot?6udzqb#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?6udzqb") format("truetype"),
    url("fonts/icomoon.woff?6udzqb") format("woff"),
    url("fonts/icomoon.svg?6udzqb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

html {
  overflow: hidden;
}
/*Initialize color theme variables */
:root {
  --color1: #000000;
  --color2: #000000;
  --color3: #000000;
  --colorBG: #000000;
  --colorFont: #000000;
  --colorDrawer: whitesmoke;
  --colorButton: #000000;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  -webkit-box-sizing: initial !important;
  box-sizing: initial !important;
}

input,
button,
select,
textarea {
  line-height: revert !important;
  font-size: 13.3px !important;
}

input,
button {
  color: var(--colorFont);
}

body {
  font-size: initial !important;
  line-height: initial !important;
  background-color: var(--colorBG);
  transition: background-color 0.5s ease-in;
}

img {
  user-select: none;
}

.App {
  font-family: "Didact Gothic" !important;
  color: var(--colorFont);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.icon {
  font-family: "icomoon";
  color: var(--color2);
  font-weight: lighter;
  text-decoration: none;
  user-select: none;
  background-color: initial;
  transition: all 250ms;

  &.sm {
    font-size: 16px !important;
  }

  &.md {
    font-size: 20px !important;
  }

  &.lg {
    font-size: 36px !important;
  }

  &:hover {
    color: var(--color1);
  }
}

.gray-icon {
  color: darkgray;
  cursor: pointer;
  user-select: none;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: var(--color3);
  border-radius: 10px;

  &:hover {
    background: var(--color2);
  }
}

#main {
  display: flex;
  flex-direction: row;
  height: 90%;
  flex: 1;

  .view {
    margin: 1em;
    margin-left: 7.5rem;
    flex-grow: 1;
    overflow-x: hidden;
    transition: margin 0.2s ease-out;

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .page-view {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;

      &.low-height {
        height: 20%;
      }
    }

    &.small {
      margin-left: 21em !important;
    }
  }
}

.rs-dropdown-menu {
  z-index: 10 !important;
}

.rs-dropdown-menu h4 {
  margin: 0;
  font-weight: bold;
  color: black;
  margin: 0.5em 0;
}

input:not(.rs-picker-search-input):not(.rs-picker-search-input input):not(
    .parameter-input
  ):not(.filter-input):not(.parameter-input input):not(
    .parameter-input:read-only
  ):not(.config-input):not(.parameter-list-param):not(.log-input):not(
    .action-button
  ):not(.param.input):not([type="checkbox"]):not(
    .rs-picker-search-bar-input
  ):not(.action-bar-search-bar):not(.billing-info-input):not(.auth-input),
select,
textarea.rs-input,
textarea {
  font-family: "Didact Gothic" !important;
  color: var(--colorFont);
  background-color: var(--colorDrawer);
  border: 2px solid lightgray;
  border-radius: 5px;
  text-align: start;
  padding: 0.5em !important;
  margin: 0.5em !important;
  resize: none;
}

input[class~="action-button"] {
  color: white !important;
}

input[class~="param input"] {
  font-family: "Didact Gothic" !important;
  background-color: var(--colorDrawer);
  border: 2px solid lightgray;
  border-radius: 5px;
  text-align: start;
}

select {
  user-select: none;
}

button {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

h1 {
  font-size: 26px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
  transition: color 0.5s ease;
}
h4 {
  font-size: 16px;
  font-weight: normal;
}
h5 {
  font-size: 14px;
}
p,
a {
  font-size: 16px;
  text-decoration: none;
}

button:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

.action-button {
  margin-right: 1em;
  font-family: "Didact Gothic";
  font-weight: bolder;
  color: white;
  background-color: var(--color2);
  border: 2px solid var(--color2);
  border-radius: 5px;
  text-align: center;
  padding: 0.5em 1em;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:disabled {
    background-color: lightgray;
    border-color: lightgray;

    &:hover {
      background-color: lightgray;
      border-color: lightgray;
    }
  }
}

.action-button:hover {
  background-color: var(--color1);
  border-color: var(--color1);
}

.action-button-color-3 {
  margin-right: 1em;
  font-family: "Didact Gothic";
  font-weight: bolder;
  color: white;
  background-color: var(--color3);
  border: 3px solid var(--color3);
  border-radius: 5px;
  text-align: center;
  padding: 0.5em 1.5em;
  cursor: pointer;
  user-select: none;
}

.action-button-2 {
  margin-right: 1em;
  font-family: "Didact Gothic";
  color: var(--color2);
  background-color: var(--colorBG);
  border: 2px solid var(--color2);
  border-radius: 5px;
  text-align: center;
  padding: 0.5em 2em;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease, color 0.2s ease;
  white-space: nowrap;
}

.action-button-2:disabled {
  color: gray;
  border-color: gray;
}

.action-button-2:hover:not(:disabled) {
  background-color: var(--color2) !important;
  color: white;
}

.action-dropdown-width {
  max-width: 200px;
  flex-grow: 1;
}

.no-margin {
  margin: 0;
}

.icon-default {
  color: var(--colorFont) !important;
}

.button-dark {
  background-color: var(--colorButton) !important;
}

a {
  text-decoration: none;
}

.text-color-2 {
  color: var(--color2);
}

.header-sort-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.header-sort-option select {
  margin: 0;
  margin-right: 0;
  width: 20em;
  font-family: "Didact Gothic";
  color: var(--color2);
  background-color: white;
  border: 2px solid var(--color2);
  border-radius: 7px;
  text-align: center;
  padding: 0.5em;
  margin: 0.6rem 0;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.header-sort-option option {
  margin: 1.5em;
  margin-right: 0;
  width: 20em;
  font-family: "Didact Gothic";
  color: var(--color2);
  background-color: white;
  border: 2px solid var(--color2);
  border-radius: 7px;
  text-align: center;
  padding: 0.5em;
}

.header-sort-option select:focus,
.header-sort-option select:hover {
  font-family: "Didact Gothic";
  color: white;
  background-color: var(--color2);
  border: 2px solid var(--color2);
  border-radius: 7px;
  text-align: center;
  padding: 0.5em;
}

.hidden {
  visibility: hidden;
}
.display-none {
  display: none !important;
}

.no-spacing {
  letter-spacing: normal;
}

.error h1 {
  font-size: 3em;
}

.right {
  align-self: flex-end;
}

.qr-display {
  position: absolute;
  width: 100%;
  height: 100% !important;
  left: 0;
  top: 0;
  background-color: rgba(77, 77, 77, 0.7);
  display: flex;
  z-index: 500;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.qr-popup-wrapper {
  max-width: 100% !important;
}

.qr-popup > div:nth-child(2) {
  margin: -1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 30%;
}

.qr-popup label {
  font-size: 1em;
  word-break: break-all;
}

.qr-list {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}

.qr-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.qr-options img {
  width: 2em;
  cursor: pointer;
}

.qr-popup div canvas {
  border-radius: 10px;
  margin-right: 1em;
}

.plan-warning {
  color: white;
  background-color: var(--color2);
  border: 2px solid var(--color2);
  border-radius: 5px;
  padding: 0.25em 0.5em;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color2);
  margin: 0.5em 0;
  min-height: 3em;
}

.plan-warning h5 {
  margin: 0;
  font-size: 0.9em;
}

.plan-warning strong {
  margin: 0 0.1em;
}

.plan-warning div {
  display: flex;
  align-items: center;
}

.table-title {
  margin: 1em 0;
}

.no-margin-top {
  margin-top: 0 !important;
}

.align-center {
  text-align: center;
}

.__react_component_tooltip {
  font-weight: normal;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 7px;
  box-shadow: 0px 1px 2px 1px #c3c3c35b;
  opacity: 1 !important;
  z-index: 3000 !important;
}

.tooltip-order {
  max-width: 20em !important;
  background-color: var(--colorBG);
  border: 1px solid rgba(53, 49, 49, 0.2);
  border-radius: 10px;
}

.new-tooltip {
  width: 100%;
  padding: 0.5em;
  text-align: left;
  display: flex;
  color: var(--colorFont);
  flex-direction: column;
  justify-content: center;
}

.new-tooltip h5 {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
}

.new-tooltip strong {
  color: var(--color3);
  font-size: 12px !important;
}

.new-tooltip strong:hover {
  text-decoration: underline;
}

.no-padding {
  padding: 0 !important;
}

.import-buttons {
  display: flex;
  flex-direction: row;
  margin: 0.8rem 0;
}

.text-white {
  color: white !important;
}

.dropdown.transfer-link {
  width: 70%;
}

.subtext-sm {
  font-size: 0.8em !important;
  margin-left: 8em !important;
}

.guide {
  max-width: 50em !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sc-gKsewC.iFoDQV {
  width: 100%;
  justify-content: space-between;
}

.rs-picker-select-menu,
.rs-picker-menu,
.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  z-index: 1200 !important;
}

.rs-picker-select-menu-item,
.rs-picker-none,
.rs-checkbox-checker label {
  font-size: 0.75em;
  font-family: "Didact Gothic";
}

.select-dropdown:not(.no-margin) {
  margin: 1em 0 !important;
}

.select-dropdown .rs-btn.rs-btn-default.rs-picker-toggle {
  border: 2px solid var(--color2) !important;
  background-color: var(--colorBG) !important;
  transition: background-color 0.2s;
}

.select-dropdown:hover .rs-btn.rs-btn-default.rs-picker-toggle {
  background-color: var(--color2) !important;
}

.select-dropdown:hover .rs-picker-toggle-value,
.select-dropdown:hover .rs-picker-toggle-caret,
.select-dropdown:hover .rs-picker-toggle-clean,
.select-dropdown:hover .rs-picker-toggle-placeholder {
  color: white !important;
}

.select-dropdown .rs-picker-toggle-value,
.select-dropdown .rs-picker-toggle-caret,
.select-dropdown .rs-picker-toggle-clean,
.select-dropdown .rs-picker-toggle-placeholder {
  color: var(--color2) !important;
}

.rs-picker-search-bar-input {
  font-family: "Didact Gothic" !important;
  color: var(--colorFont) !important;
  background-color: var(--colorDrawer) !important;
  border: 2px solid lightgray !important;
  border-radius: 5px !important;
  text-align: start;
}

.rs-notification-content .rs-notification-title {
  font-size: 0.7em;
  text-align: start;
  margin: 0;
  color: var(--color1);
  font-weight: normal;
}

.general-notification h4 {
  margin: 0;
  font-size: 1em;
  font-family: "Didact Gothic";
}

.general-notification .notification-buttons {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.rs-notification.rs-notification-top-end {
  top: 50px !important;
}
.header-link-dropdown {
  margin: 0 !important;
}

.header-dropdown {
  width: 20em;
}
.header-dropdown:last-child {
  margin-left: 1em !important;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.migrate-dropdown {
  min-width: 10em;
}

textarea.rs-input:hover {
  border-color: lightgray !important;
}

.color-3 {
  color: var(--color2);
}

.check-selector-footer {
  border-top: 1px solid var(--colorDrawer);
  color: var(--color2);
}
.check-selector-footer label {
  font-weight: bold !important;
}

.rs-picker-value-count {
  background-color: var(--color3) !important;
}

.all-links-team-list {
  margin-bottom: 1em;
}
.all-links-team-list h3 {
  margin: 0.5em 0;
}

.rs-divider-horizontal {
  margin: 0.5em 0 !important;
}

.locked-feature {
  display: flex;
  align-items: center;
}
.locked-feature i {
  margin-left: 1em;
  color: var(--color3);
}

.locked-container {
  opacity: 0.5;
  pointer-events: none;
}

.text-placeholder .rs-placeholder-paragraph-rows p {
  width: 100% !important;
  border-radius: 7px;
  margin-bottom: 0 !important;
}
.rs-picker-default,
.rs-input-group {
  margin: 0.5em;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 3em;
  height: 1.5em;
  margin-left: 1em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--colorBG);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 0.6em;
  width: 0.6em;
  left: 0px;
  bottom: 0px;
  border: 3px solid gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.always-on:before {
  border-color: rgb(36, 172, 36) !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.5em);
  -ms-transform: translateX(1.5em);
  transform: translateX(1.5em);
  border-color: rgb(36, 172, 36);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2em;
  border: 3px solid gray;
}

.slider.round:before {
  border-radius: 50%;
}
.campaign-taxonomy-selection {
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.campaign-taxonomy-selection label {
  margin: 0 1em;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: var(--color1) !important;
  border-width: 2px !important;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active,
.rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover,
.rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-check-item-focus {
  background-color: var(--colorDrawer) !important;
}

.rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover,
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  background-color: var(--colorDrawer) !important;
}

.rs-picker-select-menu-item {
  color: var(--colorFont) !important;
  font-family: "Didact Gothic" !important;
}

.rs-picker-toggle-value {
  color: var(--colorFont) !important;
  font-family: "Didact Gothic" !important;
}

.rs-picker-default,
.rs-input-group {
  margin: 0.5em;
}

.rs-picker-tag .rs-btn.rs-btn-default.rs-picker-toggle {
  border: none !important;
}

.rs-picker-default .rs-picker-tag-wrapper,
.rs-picker-input {
  border: none !important;
}
.filter-select .rs-btn.rs-btn-default.rs-picker-toggle,
.filter-select.rs-picker-date .rs-btn.rs-btn-default.rs-picker-toggle {
  border: none !important;
}
.rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-tag
  .popup-form-item
  .rs-input-group,
.popup-form-item .rs-picker-tag {
  border: 2px solid lightgray !important;
}
.rs-btn.rs-btn-default.rs-picker-toggle,
.popup-form-item .rs-input-group {
  border: 2px solid lightgray !important;
}

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle,
.rs-input-group-addon {
  background-color: var(--colorDrawer) !important;
}

.rs-tag.rs-tag-default.rs-tag-closeable {
  border: 1px solid var(--color2);
  background-color: white;
  color: var(--color2);
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: var(--color2) !important;
  background-color: var(--color2) !important;
}

.rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: var(--color2) !important;
}

.rs-picker-date .rs-btn.rs-btn-default.rs-picker-toggle {
  border: 2px solid white !important;
  border-radius: 50px !important;
}

.rs-input {
  resize: none !important;
}

.timeline-spinner {
  width: 100%;
  height: 100%;
  padding-top: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-new-method {
  display: flex;
  flex-direction: row;
  margin: 0.5em 0;
}

.payment-new-method .new-card {
  width: 100%;
  margin: auto;
  padding: 0.5em;
  font-family: "Didact Gothic" !important;
  color: var(--colorFont);
  background-color: var(--colorDrawer);
  border: 2px solid lightgray;
  border-radius: 5px;
  text-align: start;
}

.payment-new-method button {
  width: initial;
}

.rs-modal-backdrop.fade.in {
  opacity: 0.7;
}
.new-method-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.new-method-options button {
  width: 50%;
}

.new-method-options button:first-child {
  margin-right: 1em;
}

.new-method-options button:first-child:disabled {
  background-color: lightgray;
  border: none;
}

.add-new-method {
  width: 100%;
}

.rs-picker-menu .rs-picker-select-menu-item {
  font-size: 0.8em;
}

.status-color {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-right: 0.5em;
}

.status-color.red {
  background-color: #cc0000;
}
.status-color.yellow {
  background-color: #f1c232;
}
.status-color.green {
  background-color: #6aa84f;
}
.status-color.blue {
  background-color: rgb(69, 69, 197);
}

.status-color.gray {
  background-color: rgb(133, 133, 133);
}

.qr-dl-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.qr-dl-button img {
  width: 1.5em;
}

.download-qr .rs-input-group {
  margin-top: 0 !important;
}

.qr-popup textarea {
  width: 100%;
  margin: 0 !important;
  margin-bottom: 0.5em !important;
}

.download-qr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.download-qr .param-concat-manage input {
  padding-left: 11px !important;
}

.version {
  position: absolute;
  font-size: 13px;
  bottom: 1.4em;
  width: 100%;
  z-index: 999999;
}
.session-popup .rs-modal-header-close {
  display: none;
}

.header-invalid-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.invalid-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  padding: 0.25em 0.5em;
  border-radius: 20px;
  border: 2px solid lightgray;
  color: var(--color2);
  transition: background-color 0.5s;
  cursor: pointer;
}

.invalid-items i {
  color: orange;
}

.invalid-items.default-invalid-items {
  margin-left: 0.5em;
}

.invalid-items h4 {
  margin: 0;
  margin-left: 0.5em;
  font-weight: bold;
}

.invalid-items:hover {
  background-color: lightgray;
  color: white;
}

.issue-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
}

.issue-wrapper h4 {
  margin: 0;
  margin-right: 1em;
  width: 70%;
}

.issue-wrapper h4 i {
  margin-right: 0.5em;
  color: orange;
}

.rs-popover {
  font-family: "Didact Gothic";
  max-width: 35%;
  border-radius: 7px !important;
}

.rs-popover .rs-popover-title h4 {
  margin: 0;
  font-weight: bold;
  color: black;
}

.links-process-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  background-color: var(--colorDrawer);
  padding: 0.5em;
  margin-top: 0.5em;
}

.links-process-wrapper h4,
.links-process-wrapper h5 {
  font-size: 13px;
  margin: 0 0.25em !important;
}

.link-process-status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link-process-status-wrapper {
  display: flex;
  flex-direction: row;
}

.link-process-status-wrapper .link-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0.5em;
  padding: 0.25em;
  transition: background-color 0.5s;
  margin-right: 0;
  border-radius: 5px;
}
.link-process-status-wrapper .link-status:hover {
  background-color: hsla(0, 0%, 82.7%, 0.5);
}

.links-process-wrapper button {
  padding: 0.2em 1em;
}

.links-process-wrapper button:disabled {
  background-color: lightgray;
  color: white;
  border-color: lightgray;
}

.campaign-process-tip {
  max-width: 28em;
}

.links-process-wrapper .drawer-label {
  margin-bottom: 0.5em !important;
}
.shortener-actions {
  width: 100%;
  display: flex;
}

.shortener-actions button,
.shortener-actions a {
  flex-grow: 1;
  width: 100%;
}

.link-alt-process {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 7px;
  border: 2px solid lightgray;
  padding: 0.2em;
}

.link-alt-process .rs-divider {
  height: initial;
}

.finished-tag {
  background-color: var(--color1) !important;
  color: white !important;
  font-weight: bold;
  margin-left: 0.5em;
  height: 100%;
  display: flex !important;
  align-items: center;
}
.finished-tag span {
  font-size: 13px;
}

.group-input {
  display: flex;
  font-family: Didact Gothic;
  align-items: center;
  font-size: 1em;
  font-weight: bold;
  color: var(--color2);
}

.group-input i {
  font-size: 1.5em;
}

.action-button-color-3:disabled {
  background-color: lightgray;
  border-color: lightgray;
  color: white;
}

.rs-auto-complete-menu {
  max-height: 20em;
  overflow-y: scroll !important;
  min-width: 15em;
  font-size: 13px;
}

.dependency-text {
  margin: 0 !important;
  margin-left: 0.5em !important;
  font-size: 0.9em !important;
}
.import-save-button button {
  margin-top: 1em;
  width: 100%;
}

.import-save-button button:disabled {
  background-color: lightgray;
  border: none;
  cursor: default;
}

.import-save-button {
  align-self: flex-end;
  margin-right: 1em;
}

.create-links-buttongroup button {
  background-color: var(--color2) !important;
  color: white !important;
  font-weight: bold !important;
  height: 36px !important;
}

.create-links-buttongroup button:hover {
  background-color: var(--color1) !important;
}

.create-links-buttongroup {
  margin-right: 0.5em;
}

.create-links-buttongroup .rs-dropdown-item-content {
  font-size: 13px !important;
}

.bulk-confirmation-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  background-color: var(--colorDrawer);
  border-radius: 7px;
  margin-top: 1em;
  height: 5em;
}

.bulk-confirmation-wrapper .bulk-confirmation-progress {
  width: 80%;
  padding: 0.5em;
}

.bulk-confirmation-wrapper .bulk-confirmation-progress .bulk-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75em;
  flex-grow: 1;
  margin-right: 0em;
}

.bulk-confirmation-wrapper
  .bulk-confirmation-progress
  .bulk-confirmation-progress-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bulk-confirmation-wrapper
  .bulk-confirmation-progress
  .link-process-status-wrapper {
  margin-right: 1em;
}

.bulk-confirmation-progress .rs-slider .rs-slider-bar,
.bulk-confirmation-progress .rs-slider .rs-slider-bar .rs-slider-progress-bar {
  height: 1em;
  border-radius: 7px;
}

.bulk-confirmation-progress .rs-slider .rs-slider-bar {
  margin-top: 0.3em;
}
.bulk-confirmation-progress .rs-slider .rs-slider-bar {
  background-color: darkgray;
}

.bulk-confirmation-progress .rs-slider-handle {
  display: none;
}

.bulk-confirmation-wrapper .bulk-confirmation-status {
  display: flex;
  align-items: flex-end;
  padding: 0.5em;
}

.rs-modal-header .rs-modal-header-close {
  top: 25px !important;
}

.main-tooltip {
  max-width: 20em;
}

.rs-btn-toggle {
  background-color: var(--color2) !important;
}

.rs-btn-toggle-checked {
  background-color: var(--color3) !important;
}

.rs-btn-toggle:hover,
.rs-btn-toggle-checked:hover {
  background-color: var(--color1) !important;
}

.toggle {
  margin: 0 0.5em;
}

.rs-modal-sm {
  width: 550px !important;
}

#terms-and-conditions {
  background-color: var(--colorDrawer);
  height: 100%;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

#terms-and-conditions > div {
  width: 50%;
}

#main-authentication {
  background-color: var(--colorDrawer);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.main-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2em 0;
  margin-bottom: 0;
}

.main-logo img {
  width: 4em;
}

.main-logo h1 {
  margin: 0em;
  font-size: 3em;
}

.authentication {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-width: 500px;
  max-width: 800px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 6px;
  border: 2px solid lightgrey;
  padding: 20px;
}

.rs-steps-item,
.rs-steps {
  min-height: initial !important;
}

.legal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
  border: none;
  box-shadow: none;
}

.auth-lg {
  width: 50%;
}

.authentication.import {
  width: 60%;
}

.authentication .login-email {
  width: 100%;
  padding: 1em;
}

.authentication .login-email form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.authentication .login-email h5:first-child {
  margin-top: 0.5em;
}

.authentication .login-email h5 {
  text-align: center;
}

.authentication .login-email .subtext {
  text-align: middle;
  font-size: 0.7em;
}

.authentication .login-email input:not(.terms-check input) {
  width: 100%;
  margin: 0;
  margin-top: 0.5em;
}

.authentication .login-email input:not(:placeholder-shown):invalid {
  border-color: red;
}

.authentication .login-email button {
  margin-top: 1em;
  align-self: stretch;
}

.sign-up-link {
  width: 100%;
  cursor: pointer;
  color: var(--color2);
  padding-top: 1em;
  margin: 0;
}

.sign-up-link.first {
  border-top: 1px solid black;
}

.sign-up-link:hover {
  text-decoration: underline;
  color: var(--color1);
}

.debug-login {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin: 0 auto;
  margin-top: 5em;
  border-radius: 7px;
}

.debug-login div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.debug-login button {
  margin: 0.5em 0;
  width: 15em;
}

.login-email.import-selection {
  width: 70%;
}

.technology-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.technology-selection div {
  width: 12em;
  height: 12em;
  border-radius: 10%;
  background-color: white;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin: 2em;
  transition: box-shadow 0.2s;
}

.technology-selection div:not(:nth-child(2)) {
  opacity: 0.2;
}

.technology-selection div:hover:nth-child(2) {
  cursor: pointer;
  box-shadow: 0px 0px 5px 5px rgb(0 0 0 / 20%);
}

.technology-selection img {
  width: 10em;
}

.import-selection .import-skip {
  width: 100%;
  display: flex;
  justify-content: center;
}

.import-skip button {
  padding: 0.5em 2em;
}

.login-email .terms {
  max-height: 15em;
  overflow-y: auto;
  margin: 0;
  margin-bottom: 1em;
}

.login-email .terms-check {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.login-email .terms-check input {
  width: 1em;
  margin-right: 1em;
}

.authentication > .rs-container {
  width: 100%;
  padding: 0 1em;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.authentication.auth-plan-list {
  width: 80%;
  max-width: 1500px;
  padding: 0.5em 0;
}

.authentication > .rs-container h3 {
  text-align: center;
  margin: 0;
  margin-bottom: 0.5em;
}

.auth-form .rs-input-group-addon {
  width: 35px;
  display: flex;
  justify-content: center;
}

.auth-form .rs-input-group {
  border: 2px solid #d3d3d3 !important;
  margin: 0.5em 0 !important;
  background-color: var(--colorBG);
}

.auth-form .rs-input-group:first-child {
  margin-top: 0 !important;
}
.auth-form {
  flex-grow: 1;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  height: 70%;
}

.auth-form button:last-child {
  flex-grow: 2;
}

.auth-form input {
  border: none;
  margin: 0;
  padding: 0.5em 0;
}

.auth-form .auth-form-options {
  display: flex;
  flex-direction: row;
}

.auth-form .auth-select a {
  padding-left: 0;
}

.auth-form .rs-message-container {
  padding: 0.5em !important;
}

.auth-form .rs-message-body {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}

.auth-options {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
  width: 100%;
}

.auth-options button {
  color: var(--color2);
  font-weight: bold;
  width: 100%;
}

.authentication .rs-steps {
  margin-bottom: 1em;
  border: 2px solid lightgray;
  border-radius: 6px;
}

.authentication .rs-steps h5 {
  margin: 0;
}

.authentication .rs-steps-item-description {
  text-align: center;
  font-weight: bold;
  margin: 0;
}

.auth-select {
  width: 100%;
  margin: 0;
  border-radius: 0;
}

.auth-select .rs-btn {
  border: none !important;
  border-radius: 0;
  padding-left: 0;
}

.auth-form-options {
  margin-top: 2em;
}

.auth-country-select {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.auth-country-select img {
  width: 2em;
  margin-right: 0.5em;
}

.auth-country-select h4 {
  margin: 0;
  font-size: 13px;
}

.main-legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  min-width: 500px;
  max-width: 800px;
  margin: 1em auto;
}

.main-legal h5 {
  margin: 0;
}

.main-legal a {
  font-size: 13px;
  color: var(--color2);
  text-decoration: none;
}
.main-legal a:hover {
  color: var(--color3);
}

.legal-checkbox {
  margin-top: 0em;
  margin-left: 3em;
  margin-bottom: -2em;
}

.legal-checkbox a {
  text-decoration: none;
  color: var(--color2);
}

.legal-checkbox a:hover {
  color: var(--color3);
}

.legal-checkbox .rs-checkbox-inner::before {
  border: 2px solid lightgray;
}

.legal-link {
  font-size: 13px;
  color: var(--color2);
  font-weight: bold;
}

.auth-message {
  font-size: 16px;
}

.rs-steps-item-status-process
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border: 2px solid var(--color2) !important;
  font-weight: bold;
}

.rs-steps-item-status-wait
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border: 2px solid lightgray !important;
}

.cookie-table {
  margin-top: 2em;
  background-color: var(--colorDrawer) !important;
  border: 2px solid lightgray;
  border-radius: 5px;
}

.info-tag {
  border-left: 6px solid var(--color2);
  border-radius: 6px;
  flex-grow: 1;
  margin: 0.5em 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.5em;
  font-weight: bold;
}

.info-tag:not(.white) {
  background-color: var(--colorDrawer) !important;
}
.info-tag.white {
  background-color: white !important;
}

.info-tag h5 {
  margin: 0.5em 0;
  color: black;
  font-weight: bold;
}

.info-tag i {
  color: var(--color2);
  margin-right: 0.5em;
  font-size: 20px !important;
  height: 20px !important;
}

.info-tag .rs-tag-text {
  display: flex;
  flex-direction: revert;
  align-items: center;
}

.radio-option label {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  transition: color 0.3s linear, background-color 0.3s linear;
  padding: 8px 12px !important;
}

.radio-option label:hover {
  background-color: var(--colorDrawer);
}

.radio-option i {
  margin-right: 0.5em;
}

.radio-option h4 {
  margin: 0;
  color: black;
  font-size: 1em;
  font-family: "Didact Gothic";
}

.radio-group {
  border: none !important;
}

.radio-popover {
  padding: 0 !important;
}

.radio-option.rs-radio-checked i,
.radio-option.rs-radio-checked label {
  color: var(--color3);
}
.radio-option.rs-radio-checked h4 {
  font-weight: bold;
}

.float-right {
  float: right;
}

.rs-btn-toggle-disabled {
  opacity: 0.5;
}

.auth-form .rs-input-group .rs-picker-select a {
  border: none !important;
}

.auth-form .rs-message {
  margin: 1em 0;
}
/*
.rs-picker-select-menu-item {
  white-space: pre-wrap !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  display: flex;
  flex-direction: column;
}

.ReactVirtualized__Grid__innerScrollContainer > div {
  position: initial !important;
  height: initial !important;
}
*/

.rs-alert-item-content div {
  word-break: break-all;
}

.feature-anim-enter {
  height: 0%;
}
.feature-anim-enter-active {
  height: 100%;
  transition: height 300ms, transform 200ms;
}
.feature-anim-exit {
  height: 100%;
}
.feature-anim-exit-active {
  height: 0%;
  transition: height 300ms, transform 200ms;
}

.plan-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.plan-list-container h3 {
  margin: 0;
}
.cycle-selection {
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cycle-selection .switch {
  margin: 0 1em;
}

.cycle-selection .selected {
  color: var(--color2);
  font-weight: bolder;
}

.cycle-selection h4 {
  margin: 0.5em 0;
}

.rs-dropdown-menu h4 {
  white-space: pre-wrap;
}

.plan-popular {
  margin-left: 14.5%;
}

.plan-popular h5 {
  margin: 0;
  color: var(--color2);
  font-weight: bold;
  width: 30%;
  margin: 0 auto;
}

.dep-count-tag {
  border-radius: 20px !important;
  padding: 0 1em !important;
  font-weight: bold !important;
  background-color: var(--color2) !important;
  color: white !important;
}

.dep-count-tag.selected {
  background-color: var(--color3) !important;
}

.expandable-button {
  display: inline-flex !important;
  align-items: center;
  overflow: hidden;
  width: auto !important;
  max-width: 34px;
  -webkit-transition: max-width 0.5s !important;
  transition: max-width 0.5s !important;
  &:hover {
    max-width: 300px;
  }
  i {
    margin-right: 1em;
  }
}

.authentication form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.authentication .form-option {
  align-self: flex-end;
  color: var(--color2);
  padding: 0;
}

.authentication .form-option:hover {
  color: var(--color3);
}

.authentication .form-sublabel {
  margin: 0;
  color: gray;
  align-self: end;
  font-style: italic;
}

.signup-progress .progress-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.signup-progress .rs-divider {
  margin: 0;
}

.signup-progress .rs-progress {
  padding: 0.5em 0;
}

.signup-progress .rs-icon {
  font-size: 20px !important;
  height: 20px !important;
  color: lightgray;
  transition: color 250ms ease-in-out;

  &.active {
    color: var(--color3);
  }
}

.authentication .rs-checkbox-checker {
  padding-top: 0 !important;
}

.lang-picker {
}

.notification-error-list {
  border: 2px solid red;
  padding: 0.5em;
  border-radius: 6px;
  margin-top: 0.5em;
  max-height: 15em;
  overflow: auto;
  overflow-x: hidden;
}

.notification-error-list .rs-tag {
  margin: 0.75em;
}

.routing-wrapper .rs-panel-body {
  padding: 0;
}

.productfruits--container .productfruits--lifering {
  transform: scale(0.75) !important;
}

body {
  &.productfruits--banners-shown {
    .App {
      height: calc(100vh - 44px);
    }
    .sidenav,
    .sliding-drawer {
      height: calc(100% - 44px);
    }
  }
}

.rs-alert {
  z-index: 10800000 !important;
}

.auth-input:disabled {
  opacity: 0.5;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: 1em;
  text-align: center;
  h1 {
    font-size: 120px;
    color: var(--color2);
    letter-spacing: 0.1em;
    margin: 0;
  }
  button {
    width: 100%;
  }
  img {
    width: 60%;
    max-width: 600px;
  }
}

.coupon-announcement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--color3);
  color: white;
  border-radius: 6px;
  margin: 0.5em 0;
  padding: 0.5em;

  h4 {
    margin: 0;
  }

  .rs-tag {
    margin-right: 0.5em;
    background-color: var(--color2);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      margin-right: 0.5em;
    }
    span {
      font-weight: bold;
    }
  }
}

@reset-import: false;@font-size-base: 13px;@font-family-base: Didact Gothic;@input-bg: var(--colorDrawer);@calendar-table-cell-content-hover-bg: var(--colorDrawer);@calendar-table-cell-content-selected-color: var(--color3);@calendar-table-cell-content-today-border-color: var(--color3);@calendar-right-btn-ok-bg: var(--color2);@calendar-option-color: var(--color2);@picker-menu-item-hover-bg: var(--colorDrawer);@slider-bar-default-bg: inherit;@slider-bar-hover-bg: inherit;@slider-progress-bar-bg: var(--color3);@steps-bg: white;@steps-icon-process-bg: var(--color2);@steps-icon-process-color: white;@steps-content-process-color: var(--color3);@steps-icon-finish-color: var(--color3);